<script setup lang="ts">
import { useGlobalApi } from '~/api/Global'

const { getLanguages } = useGlobalApi()
const { locale } = useI18n()

const { data: languages } = await useAsyncData('languages', () => getLanguages())

useHead({
  htmlAttrs: {
    lang: locale
  }
})
</script>

<template>
  <div>
    <Header :languages="languages.data" />
    <slot />
  </div>
</template>
