<script setup lang="ts">
import type { Language } from '~/types/Global'

const switchLocalePath = useSwitchLocalePath()
const localePath = useLocalePath()

defineProps<{
  languages: Language[] | null
}>()
</script>

<template>
  <header class="sticky top-0 z-[3] border-b border-white bg-black py-4">
    <div class="container flex items-center justify-between">
      <nav>
        <ul class="flex items-center gap-5">
          <li>
            <NuxtLink :to="localePath('/')" class="text-xl text-yellow" active-class="underline">
              {{ $t('header.home') }}
            </NuxtLink>
          </li>
          <li>
            <NuxtLink :to="localePath('/projects/')" class="text-xl text-yellow" active-class="underline">
              {{ $t('header.projects') }}
            </NuxtLink>
          </li>
        </ul>
      </nav>
      <div v-if="languages.length > 1" class="flex gap-5">
        <NuxtLink
          v-for="item in languages"
          :key="item.language"
          class="cursor-pointer border-none bg-none text-lg text-white"
          active-class="underline"
          :to="switchLocalePath(item.language)"
        >
          {{ item.language }}
        </NuxtLink>
      </div>
    </div>
  </header>
</template>
